import { Fragment, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Slider from 'react-slick';
import { getLanguagePack, getTester, sendEvent } from './api';
import { ReactComponent as GooglePlayIcon } from './assets/svgs/google-play-icon.svg';
import { ReactComponent as GooglePlayText } from './assets/svgs/google-play-text.svg';
import { ReactComponent as ArrowRight } from './assets/svgs/arrow-right.svg';
import { ReactComponent as ArrowLeft } from './assets/svgs/arrow-left.svg';
import SecondScreen from './assets/svgs/2nd-screen.svg';
import ThirdScreen from './assets/svgs/3rd-screen.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.scss';

const sliderSettings = {
    slidesToShow: 1,
    infinite: false,
    speed: 200,
};

function App() {
    const [urlParams, setUrlParams] = useSearchParams();
    const languageCode = urlParams.get('lang') || 'en';
    const userId = urlParams.get('userid') || '';
    const cellName = urlParams.get('cell') || '';
    const slide = +urlParams.get('slide') || 1;
    const versionParam = urlParams.get('version');
    const [languagePack, setLanguagePack] = useState([]);
    const [testerId, setTesterId] = useState('');
    const [storeRedirectionEvent, setStoreRedirectionEvent] = useState(false);
    const sliderRef = useRef();
    const timeoutRef = useRef();
    const inputRef = useRef();

    sliderSettings.initialSlide = slide - 1;

    const canGoToStore = slide === 3 && testerId;

    const next = () => {
        const appVersion = versionParam === '1' ? '' : '2';
        const storeUrl = `intent://details?id=com.app.eyeseetest${appVersion}#Intent;scheme=market;package=com.android.vending;end`;

        if (canGoToStore && !storeRedirectionEvent) {
            sendEvent(testerId, 'User accessed store.', 1);
            setStoreRedirectionEvent(true);
        }
        if (canGoToStore) {
            copyToClipboard();
            window.location.replace(storeUrl);
        }
        const newSlide = slide < 3 ? slide + 1 : 3;
        urlParams.set('slide', newSlide);
        setUrlParams(urlParams.toString());
    };

    const previous = () => {
        const newSlide = slide > 1 ? slide - 1 : 1;
        urlParams.set('slide', newSlide);
        setUrlParams(urlParams.toString());
    };

    const handleSliderInit = () => {
        if (!sliderRef.current) return;
        sliderRef.current.slickGoTo(slide - 1);
    };

    const copyToClipboard = () => {
        if (!inputRef.current) {
            return;
        }
        inputRef.current.focus();
        inputRef.current.select();
        const isCopied = document.execCommand('copy');
        sendEvent(testerId, `clipboard copied via input ${isCopied}`, 2);
    };

    const writeToClipboard = (text) => {
        window.navigator.clipboard
            .writeText(text)
            .then(() => {
                sendEvent(text, `clipboard copied via navigator`, 2);
            })
            .catch((err) => {
                sendEvent(text, `failed to write to clipboard via navigator: ${err}`, 2);
            });
    };

    useEffect(() => {
        getLanguagePack().then((data) => {
            const lang =
                data.find(({ code }) => code === languageCode) ||
                data.find(({ code }) => code === 'en');
            lang && setLanguagePack(lang.data);
        });
    }, [languageCode]);

    useEffect(() => {
        if (!sliderRef.current) return;
        urlParams.set('slide', slide);
        setUrlParams(urlParams.toString());
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => sliderRef.current.slickGoTo(slide - 1), 200);
    }, [slide, setUrlParams, urlParams]);

    useEffect(() => {
        if (!cellName || !userId) return;
        getTester().then((tester) => {
            const id = `${tester.uuid}&${tester.externalTesterId}`;
            setTesterId(id);
            if (inputRef.current) {
                inputRef.current.value = id;
            }
        });
    }, [cellName, userId]);

    useEffect(() => {
        if (!testerId) return;
        if (!window.navigator.clipboard) {
            sendEvent(testerId, `no Navigator.clipboard`, 2);
            return;
        }
        if (window.navigator.permissions) {
            const queryOpts = { name: 'clipboard-write', allowWithoutGesture: true };
            window.navigator.permissions.query(queryOpts).then(({ state }) => {
                sendEvent(testerId, `clipboard-write status ${state}`, 2);
                if (state === 'granted' || state === 'prompt') {
                    writeToClipboard(testerId);
                }
            });
        } else {
            writeToClipboard(testerId);
        }
    }, [testerId]);

    if (!languagePack.length) return null;

    const Next = () => (
        <div className="nav-item">
            <span>{languagePack?.[0]?.next}</span>
            <ArrowRight />
        </div>
    );

    return (
        <div className="App">
            <Slider ref={sliderRef} {...sliderSettings} onInit={handleSliderInit}>
                <Slide1 {...languagePack[0]} />
                <Slide2 {...languagePack[1]} />
                <Slide3 {...languagePack[2]} />
            </Slider>
            <div className="nav">
                <div onClick={previous}>
                    {slide !== 1 && (
                        <div className="nav-btn">
                            <ArrowLeft /> <span>{languagePack?.[0]?.back}</span>
                        </div>
                    )}
                </div>
                <div onClick={next}>
                    {canGoToStore ? (
                        <button type="button">
                            <Next />
                        </button>
                    ) : (
                        <Next />
                    )}
                </div>
            </div>
            <input ref={inputRef} />
        </div>
    );
}

const Slide1 = ({ text }) => (
    <article>
        <h2>1</h2>
        <div className="text">
            {text.map((textFragment) => (
                <Fragment key={textFragment}>
                    <span>{htmlDecode(textFragment)}</span>
                    <br />
                </Fragment>
            ))}
        </div>
        <div className="google">
            <GooglePlayIcon />
            <GooglePlayText />
        </div>
    </article>
);

const Slide2 = ({ text }) => (
    <article>
        <h2>2</h2>
        <div className="text">
            {text.map((textFragment) => (
                <Fragment key={textFragment}>
                    <p>{htmlDecode(textFragment)}</p>
                </Fragment>
            ))}
        </div>
        <div>
            <img src={SecondScreen} alt="google play install" />
        </div>
    </article>
);

const Slide3 = ({ text, endText }) => (
    <article>
        <h2>3</h2>
        <div className="text">{htmlDecode(text)}</div>
        <img src={ThirdScreen} alt="google play open" />
        <div className="text">{htmlDecode(endText)}</div>
    </article>
);

const htmlDecode = (input) => {
    const element = document.createElement('div');
    element.innerHTML = input;
    return [...element.childNodes]
        .filter(({ nodeName }) => nodeName === '#text' || nodeName === 'B')
        .map(({ nodeName, textContent }) =>
            nodeName === 'B' ? (
                <b key={textContent}>{textContent}</b>
            ) : (
                <Fragment key={textContent}>{textContent}</Fragment>
            ),
        );
};

export default App;
