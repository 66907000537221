import axios from 'axios';

const INSTANCE = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

const ROUTES = {
    languages: '/languages/download',
    tester: '/tester',
    events: '/events',
};

const request = (options) => INSTANCE(options).then(({ data }) => data);

export const get = (url, options) => request({ url, method: 'get', ...options });

export const getLanguagePack = () => get(ROUTES.languages);

export const getTester = () => get(`${ROUTES.tester}${window.location.search}`);

export const sendEvent = (testerUuid, action = '', code) =>
    get(`${ROUTES.events}?testerUuid=${testerUuid}&action=${action}&code=${code}`);

export default INSTANCE;
